// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vZRtz3MdNH4RaNLECOfDsg\\=\\={display:flex;justify-content:center}", "",{"version":3,"sources":["webpack://./src/components/pages/users/userDetailPage.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,sBAAA","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "vZRtz3MdNH4RaNLECOfDsg=="
};
export default ___CSS_LOADER_EXPORT___;
