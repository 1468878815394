// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VsQnx4pP\\+zBw-fllIv744w\\=\\={display:flex;flex-direction:column;width:100%}", "",{"version":3,"sources":["webpack://./src/components/shared/inputs/dropdown.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,qBAAA,CAEA,UAAA","sourcesContent":[".dropdown {\n  display: flex;\n  flex-direction: column;\n\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "VsQnx4pP+zBw-fllIv744w=="
};
export default ___CSS_LOADER_EXPORT___;
