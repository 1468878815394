// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pxE1oVsTdyVEj2ft10pirQ\\=\\={display:flex;flex-direction:column;margin-bottom:30px;width:100%}._0eTwaniIcTp67ys3kA0uhg\\=\\={padding:5px}.JnVT\\+ycep07FcfYPqOuhOg\\=\\={color:red}.JnVT\\+ycep07FcfYPqOuhOg\\=\\= ._0eTwaniIcTp67ys3kA0uhg\\=\\={border-color:red}", "",{"version":3,"sources":["webpack://./src/components/shared/inputs/textField.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,qBAAA,CAEA,kBAAA,CACA,UAAA,CAGF,6BACE,WAAA,CAGF,6BACE,SAAA,CAEA,0DACE,gBAAA","sourcesContent":[".textField {\n  display: flex;\n  flex-direction: column;\n\n  margin-bottom: 30px;\n  width: 100%;\n}\n\n.input {\n  padding: 5px;\n}\n\n.errorField {\n  color: red;\n\n  .input {\n    border-color: red;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textField": "pxE1oVsTdyVEj2ft10pirQ==",
	"input": "_0eTwaniIcTp67ys3kA0uhg==",
	"errorField": "JnVT+ycep07FcfYPqOuhOg=="
};
export default ___CSS_LOADER_EXPORT___;
