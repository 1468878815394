// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sY8amDm2RbAbyyFab0bQ1w\\=\\={width:100%;border-collapse:collapse;margin-top:20px}.sY8amDm2RbAbyyFab0bQ1w\\=\\= th,.sY8amDm2RbAbyyFab0bQ1w\\=\\= td{border:1px solid #ddd;padding:8px;text-align:left}.sY8amDm2RbAbyyFab0bQ1w\\=\\= th{background-color:#639}.sY8amDm2RbAbyyFab0bQ1w\\=\\= .iMWKnX7u3Onm0ygbpiPdpA\\=\\={margin-top:20px;border:1px solid #ddd;padding:5px}", "",{"version":3,"sources":["webpack://./src/components/pages/assignments/scoreboard.scss"],"names":[],"mappings":"AAEA,4BACE,UAAA,CACA,wBAAA,CACA,eAAA,CAEA,8DACE,qBAAA,CACA,WAAA,CACA,eAAA,CAGF,+BACE,qBAAA,CAIF,wDACE,eAAA,CACA,qBAAA,CACA,WAAA","sourcesContent":["@import 'variables';\n\n.scoreboardTable {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 20px;\n\n  th, td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: left;\n  }\n\n  th {\n    background-color: rebeccapurple;\n\n\n  }\n  .scoreboardContainer {\n    margin-top: 20px;\n    border: 1px solid #ddd;\n    padding: 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scoreboardTable": "sY8amDm2RbAbyyFab0bQ1w==",
	"scoreboardContainer": "iMWKnX7u3Onm0ygbpiPdpA=="
};
export default ___CSS_LOADER_EXPORT___;
