// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qCd4hgmchuyAiy5yNdMqEQ\\=\\={height:100%;display:flex;flex-direction:column;overflow-y:auto;padding:0vw}.yBeBC8HkNbvJn8PXdOPvoA\\=\\={margin-top:20px;flex-grow:1;padding:10px 50px}", "",{"version":3,"sources":["webpack://./src/components/shared/layouts/pageWrapper.scss"],"names":[],"mappings":"AAAA,4BACE,WAAA,CACA,YAAA,CACA,qBAAA,CAGA,eAAA,CACA,WAAA,CAGF,4BACE,eAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".page {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n\n  //navigation bar should be on top of the page each time\n  overflow-y: auto;\n  padding: 0vw;\n}\n\n.content {\n  margin-top: 20px;\n  flex-grow: 1;\n  padding: 10px 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "qCd4hgmchuyAiy5yNdMqEQ==",
	"content": "yBeBC8HkNbvJn8PXdOPvoA=="
};
export default ___CSS_LOADER_EXPORT___;
